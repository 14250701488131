.cookie--wrap {
    background: #444;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: transform 150ms ease-out;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    z-index: 999999;
    width: 100%;

    a {
        color: inherit;
    }

    @include media('>=tablet') {
        width: 100%;
        max-width: 900px;
    }
}

.cookie--wrap__on {
    transform: translateX(-50%);
}

.cookie--body {
    padding: 20px;
}

.cookie--txt {
    margin-bottom: 20px;
}

.cookie--pulsanti {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.cookie--btn {
    min-width: 200px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    background: rgba(#fff,0.3);
    padding: 10px;
    margin: 0 10px;

    &:hover {
        background: rgba(#fff,0.2);
    }
}

.cookie--chiudi {
    background: #2871a0;
    color: #fff;
    cursor: pointer;

    &:hover {
        background: darken(#2871a0,10%);
    }
}
