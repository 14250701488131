@import '_include_media';

$gabbia: 1200px;
$gabbiastretta: 900px;

$altezzaHeader: 80px;
$altezzaHeaderShrink: 100px;
$altezzaHeaderMobile: 50px;

//### COLORI ###
$rosso: #E1001A;
$grigio: #d0d9dd;
$blu: #314d62;

$color: #50504e;
$link: #f60;

$line: 1.3;


//### FONT ###
$barlow: 'Barlow', sans-serif;
$roboto: 'Roboto', sans-serif;

//### Z-INDEX ###
$zindexHeader: 10;
$zindexNav: 10;
$zindexNavButton: 99999;

@mixin gabbia {
    max-width: $gabbia;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include media('>1200px') {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin gabbiaStretta {
    max-width: $gabbiastretta;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include media('>900px') {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin absolutecenter {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@mixin antialias()
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
