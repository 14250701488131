@import '_reset';
@import '_utility';
@import '_font';
@import '_cookie';
@import '_nav';
@import '_footer';


body {
	font-family: $roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	color: $color;
	background-color: #fbfbfb;
}

a {
	color: inherit;
}

b, strong { font-weight: 700; }
i { font-style: italic; }

ul, ul li {
	margin: 0; padding: 0; list-style-type: none;
}

.center {
	text-align: center;
}

.freeze {
    overflow-y: hidden;
}

.nomargin {
	margin: 0 !important;
}

.desktop {
	display: none;

	@include media('>tablet') {
		display: block;
	}
}

.mobile {
	display: block;
	text-align: center;

	@include media('>tablet') {
		display: none;
	}
}

.img--block {
	display: block;
	width: auto;
	max-width: 100%;

	img {
		width: auto;
		max-width: 100%;
	}
}

.spacer {
	height: 5px;

	@include media('>tablet') {
		height: 40px;
	}
}

.main--wrap {
	overflow-x: hidden;
	padding-top: $altezzaHeaderMobile;

	@include media('>tablet') {
		padding-top: $altezzaHeader;
	}
}

.row {
    margin-bottom: 40px;
}

.layout {
    @include gabbia;
}

.layoutStretto {
    @include gabbiaStretta;
}

.top--wrap {
    padding-top: 50px;
    padding-bottom: 25px;
	background: linear-gradient(to top, transparent 0%, rgba($blu,0.5) 100%),url('/assets/img/top-bg.jpg');
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;

	@include media('>=tablet') {
		padding-top: 75px;
	    padding-bottom: 35px;
	}

	@include media('>=desktop') {
		padding-top: 100px;
	    padding-bottom: 50px;
	}
}

.title {
	font-family: $roboto;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 10px;
	line-height: 1;
	color: #fff;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

    span { color: $rosso; }

	@include media('>tablet') {
		font-size: 72px;
		margin-bottom: 20px;
	}
}

.subtitle {
	font-family: $roboto;
    font-size: 20px;
	color: #fff;

    span { color: $rosso; }

	@include media('>tablet') {
		font-size: 26px;
	}
}

.box {
	display: flex;
	flex-wrap: wrap;
}

.side {
	width: 100%;
	margin-bottom: 20px;

	@include media('>=desktop') {
		width: 50%;
		margin-bottom: 0;
		&:nth-child(odd) { padding-right: 20px; }
		&:nth-child(even) { padding-left: 20px; }
	}

	img {
		margin: 0 auto;
	}
}

.txt {
	font-size: 19px;

	p {
		margin-bottom: 20px;
	}

	b, strong {
		color: $rosso;
	}
}

.pulsante--wrap {
    text-align: center;
}

.pulsante {
	font-family: $roboto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: $rosso;
    padding: 6px 14px;
    text-align: center;
	letter-spacing: 1px;
    line-height: 1.5;
    border: 0;
    border-radius: 3px;
    width: auto;
    max-width: 100%;
    margin-bottom: 20px;
    cursor: pointer;

    @include media('>=tablet') {
        font-size: 16px;
        letter-spacing: 1px;
        padding: 10px 20px;
    }

    &:hover {
        background: darken($rosso, 10%);
    }

    &.negative {
        background: #fff;
        color: $rosso;
        &:hover {
            background: darken(#fff, 10%);
        }
    }
}

.artwork {
	background: linear-gradient(to bottom, transparent 0%, $rosso 100%), url('/assets/img/home-artwork.jpg'), $rosso;
	background-repeat: no-repeat, no-repeat;
	background-position: center center, center center;
	background-size: cover, cover;
	color: #fff;

	@include media('>=1600px') {
		height: 550px;
		background: url('/assets/img/home-artwork.jpg'),$rosso;
		background-repeat: no-repeat;
		background-position: 10% center;
		background-size: auto 100%;
	}
}

.artwork--layout {
	width: 100%;
	@include gabbia;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 300px 0 40px 0;

	@include media('>=1600px') {
		padding: 0;
	}
}

.artwork--content {
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 20px;
	max-width: 400px;
}

.artwork--deco {
	width: 100%;
	background: linear-gradient(to right, transparent 0%, transparent 50%, transparent calc(50% + 1px), #fff calc(50% + 1px), #fff calc(50% + 2px), transparent calc(50% + 2px), transparent 100%);
}

.artwork--info {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.artwork--txt {
	width: 100%;
	font-size: 18px;
	margin-bottom: 20px;

	@include media('>=tablet') {
		font-size: 24px;
	}
}

.artwork--firma {
	width: 100%;
	font-size: 14px;
	font-weight: 300;
	font-style: italic;
	text-align: right;

	@include media('>=tablet') {
		font-size: 18px;
	}
}


.breadcrumbs--wrap {
	margin-bottom: 50px;
}

.breadcrumbs--item {
	text-transform: uppercase;
	font-weight: bold;
	color: $rosso;
	font-size: 13px;
	text-decoration: none;
}

.pagina--title {
	font-size: 35px;
	font-weight: 300;
	letter-spacing: -1px;
	text-transform: uppercase;
	color: $rosso;
	padding-bottom: 20px;
	margin-bottom: 30px;
	position: relative;
	line-height: 1.2;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 25%;
		border-bottom: 5px solid $rosso;
	}

	@include media('>=tablet') {
		font-size: 47px;
	}
}
