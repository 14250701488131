@import '_utility';

.nav--wrap {
    width: 100%;
    position: fixed;
    z-index: $zindexHeader;
    height: $altezzaHeaderMobile;
    background: #fff;
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        height: $altezzaHeader;
    }
}

.nav--layout {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gabbia;
}

.nav--logo svg {
    display: block;
    width: 130px;
    transition: all 250ms ease-in-out;

    @include media('>tablet') {
        width: 250px;
        padding: 0 20px 0 0;
    }
}

nav {
    display: block;
    position: fixed;
    top: $altezzaHeaderMobile;
    left: 0;
    height: calc(100% - #{$altezzaHeaderMobile});
    overflow-y: auto;
    width: 100%;
    background: url("/assets/img/cog-menu-bg.svg") no-repeat left bottom, $blu;
    background-size: cover;
    border-bottom: 10px solid darken($blu,10%);
    z-index: $zindexNav;
    transform: translateX(110%);
    box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
    transition: all 250ms ease-in-out;
    padding: 30px 0 0 0;

    @include media('>tablet') {
        position: relative;
        top: auto;
        height: auto;
        background: #fff;
        background-size: auto;
        transform: none;
        display: flex;
        justify-content: flex-end;
        position: relative;
        bottom: -11px;
        box-shadow: none;
        transition: all 250ms ease-in-out;
        padding: 0;
        border-bottom: 0;
    }
}

nav.show {
    transform: translateX(0%);
}

.nav--bottom__mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 20px 20px 40px 20px;
    line-height: 1.5;
    font-size: 1.2rem;
    width: 100%;
    display: block;
    text-align: center;

    @include media('>tablet') {
        display: none;
    }
}

.nav--bottom__mobile__tel {
    a {
        font-size: 1rem;
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        svg {
            width: 1rem;
            height: 1rem;
            margin-right: 10px;

            path {
                fill: $blu;
            }
        }
    }
}

.nav--bottom__mobile__mail {
    font-size: 1rem;
    letter-spacing: 1px;
}


.nav--item {
    display: block;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 28px;
    text-align: center;

    &.current {
        color: $rosso;
    }

    @include media('>tablet') {
        padding: 0 0 0 20px;
        font-size: 16px;
        background: #fff;
        color: $color;
        display: inline-block;
        font-weight: normal;
        text-align: left;

        &:hover {
            color: $rosso;
        }

        &.current {
            color: $rosso;
            background: #fff;
            font-weight: bold;
        }
    }
}


//pulsante menu
.nav--btn {
    display: inline-block;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background: $rosso;
    text-align: center;
    padding: 0.3rem 0;
    font-size: 0;
    cursor: pointer;
    z-index: $zindexNavButton;
    transition: right, background 250ms ease-in-out;
    -moz-transition: right, background 250ms ease-in-out;
    -webkit-transition: right, background 250ms ease-in-out;

    @include media('>tablet') {
        display: none
    }

    &:before {
        font-size: 2rem;
        color: #fff;
    }

    &:focus {
        outline: none;
    }

    span {
        display: block;
        position: absolute;
        top: 23px;
        left: 25%;
        right: 25%;
        height: 3px;
        background: #fff;

        &:after, &:before {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: #fff;
            content: "";
        }

        &:after {
            bottom: -9px;
        }

        &:before {
            top: -9px;
        }
    }
}

//struttura hamburger
.hamburger {
    span {
        transition: background 0ms 250ms;
        -moz-transition: background 0ms 250ms;
        -webkit-transition: background 0ms 250ms;

        &:before, &:after {
            transition-duration: 250ms, 250ms;
            -moz-transition-duration: 250ms, 250ms;
            -webkit-transition-duration: 250ms, 250ms;
            transition-delay: 250ms, 0ms;
            -moz-transition-delay: 250ms, 0ms;
            -webkit-transition-delay: 250ms, 0ms;
        }

        &:before {
            transition-property: top, transform;
            -moz-transition-property: top, transform;
            -webkit-transition-property: top, transform;
        }

        &:after {
            transition-property: bottom, transform;
            -moz-transition-property: bottom, transform;
            -webkit-transition-property: bottom, transform;
        }
    }
}

//stato attivo dell'hamburger
.hamburger.active {
    background: $blu;
    span {
        background: none;

        &:before, &:after {
            transition-delay: 0ms, 250ms;
            -moz-transition-delay: 0ms, 250ms;
            -webkit-transition-delay: 0ms, 250ms;
        }

        &:before {
            top: 0;
            transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        &:after {
            bottom: 0;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
}
