@import '_utility';

footer {
    padding: 50px 0 30px 0;
    background: $rosso;
    border-bottom: 8px solid darken($rosso,10%);
    color: #fff;
}

.footer--content {
    font-size: 14px;
    position: relative;
    @include gabbia;
}

.footer--logo {
    display: block;
    width: 200px;
    margin-bottom: 40px;
    img {
        display: inline-block;
        width: 100%;
    }
}

.footer--privacy {
    font-size: 14px;
}

.credits {
    display: block;
    position: relative;
    margin-top: 40px;

    @include media('>tablet') {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        right: 20px;
    }

    img {
        width: 70px;
        height: auto;
    }
}
